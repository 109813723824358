import "@fontsource/lato";
import Lara from "@primevue/themes/lara";
import { definePreset } from "@primevue/themes";
import "primeicons/primeicons.css";

import { createApp } from "vue";
import * as VueRouter from "vue-router";
import App from "./App.vue";
import PrimeVue from "primevue/config";

import GameView from "./pages/GameView";
import GameList from "./pages/GameList";

const routes = [
  { path: "/", component: GameList },
  { path: "/game/:gameId", component: GameView },
];
const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
});

const MyThemePreset = definePreset(Lara, {
  semantic: {
    primary: {
      50: "{indigo.50}",
      100: "{indigo.100}",
      200: "{indigo.200}",
      300: "{indigo.300}",
      400: "{indigo.400}",
      500: "{indigo.500}",
      600: "{indigo.600}",
      700: "{indigo.700}",
      800: "{indigo.800}",
      900: "{indigo.900}",
      950: "{indigo.950}",
    },
    colorScheme: {
      light: {
        entry: {
          bg: "{gray.200}",
          redtext: "{red.800}",
        },
        tile: {
          text: "{slate.950}",
          1: "{blue.100}",
          2: "{green.100}",
          3: "{yellow.100}",
          4: "{pink.100}",
          5: "{orange.100}",
        },
      },
      dark: {
        entry: {
          bg: "{gray.600}",
          redtext: "{yellow.400}",
        },
        tile: {
          text: "{slate.900}",
          1: "{cyan.700}",
          2: "{green.700}",
          3: "{yellow.500}",
          4: "{pink.700}",
          5: "{orange.500}",
        },
      },
    },
  },
});

const app = createApp(App);
app.use(PrimeVue, {
  theme: {
    preset: MyThemePreset,
    options: {
      prefix: "",
    },
  },
});
app.use(router);

import ConfirmationService from "primevue/confirmationservice";
app.use(ConfirmationService);

import ToastService from "primevue/toastservice";
app.use(ToastService);

app.mount("#app");
