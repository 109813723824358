<template>
	<div class="entered">
		<LetterTile v-for="(letter, idx) in selected" :key="idx" :letter="letter" :letterValue="points[letter]" @click="removeLetter(idx)" />
		<span class="clear"><a @click.prevent="selected = []"><i class="pi pi-times"></i></a></span>
		<span class="score">{{ score }}</span>
	</div>
	<div v-if="letters.length > 0" class="available">
		<LetterTile v-for="(letter, idx) in letters" :key="idx" :letter="letter" :letterValue="points[letter]" @click="append(letter)" />
	</div>
	<div v-else class="available">
		<Skeleton class="tile" v-for="i in 7" :key="i" width="50px" height="50px" />
	</div>
	<div class="buttons">
		<Button label="Shuffle" icon="pi pi-refresh" @click="shuffle" />
		<Button :disabled="locking || !lockable" label="Lock" :icon="locking ? 'pi pi-spin pi-spinner' : 'pi pi-lock'" class="p-button-danger" @click="lock" />
	</div>
	<div v-if="leaders == null" class="leaderboard">
		<h2>{{ gameName }} Leaderboard</h2>
		<div v-for="idx in 3" :key="idx" class="player">
			<Skeleton shape="circle" width="60px" height="60px" />
			<Skeleton :style="{marginLeft: '16px'}" height="2rem" />
		</div>
	</div>
	<div v-else class="leaderboard">
		<h2>{{ gameName }} Leaderboard</h2>
		<div :style="{textAlign: 'center', fontStyle: 'italic'}" v-if="leaders.length == 0">No locks yet, set the bar high!</div>
		<div v-for="(player, idx) in leaders" :key="idx" class="player">
			<div>
				<Avatar :label="idx + 1" size="large" shape="circle" />
				<div class="name">{{ player[0] }}</div>
			</div>
			<div v-if="amLocked">
				<div class="word">{{ player[1] }}</div>
				<div class="score">{{ player[2] }}</div>
			</div>
			<div v-else>
				<div class="word hidden"><i class="pi pi-lock" style="font-size: 1.5rem"></i></div>
			</div>
		</div>
	</div>
	<div class="buttons" style="padding-bottom: 32px">
		<Button label="Yesterday" icon="pi pi-calendar" @click="showYesterday = true" />
		<Button label="Share" icon="pi pi-share-alt" @click="share()" />
	</div>
	<ConfirmDialog group="game"></ConfirmDialog>
	<Toast position="top-center"></Toast>
	<Dialog header="Yesterday's Results" footer=" " v-model:visible=showYesterday class="dodgle-modal" modal dismissableMask>
		<YesterdayView :gameId="gameId" />
	</Dialog>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import Button from 'primevue/button';
import Avatar from 'primevue/avatar';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import Skeleton from 'primevue/skeleton';
import Toast from 'primevue/toast';
import confetti from 'canvas-confetti';
import LetterTile from '../components/LetterTile';
import YesterdayView from '../components/Yesterday';
import { getScore } from '../utils/letters';
import { getUserId, getUserName, getGameLabel, setGameLabel } from '../utils/storage';
import { DEFAULT_LETTER_SCORES } from '../utils/letters';

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;

export default {
  name: 'GameView',
  components: {
		Avatar,
		Button,
		Dialog,
		ConfirmDialog,
		Skeleton,
		Toast,
		LetterTile,
		YesterdayView,
  },
	data() {
		return {
			gameData: {},
			letters: [],
			points: {},
			selected: [],
			leaders: null,
			amLocked: false,
			locking: false,
			showYesterday: false,
		}
	},
	computed: {
		selectedWord() {
			return this.selected.join('');
		},
		score() {
			return getScore(this.selectedWord, this.points)
		},
		lockable() {
			return !this.amLocked && this.selected.length > 0;
		},
		gameId() {
			return this.$route.params.gameId;
		},
		gameName() {
			return getGameLabel(this.gameId) || "New Game";
		},
		userId() {
			return getUserId();
		},
		userLabel() {
			return getUserName();
		},
	},
	mounted() {
		axios.get(`${BACKEND_URL}/game/${this.gameId}`, {
			params: {
				gameLabel: this.gameName,
			},
		}).then(resp => {
			this.gameData = resp.data;
			this.letters = resp.data.letters;
			this.points = resp.data.points || DEFAULT_LETTER_SCORES;
			if (resp.data.gameLabel && resp.data.gameLabel !== 'New Game') {
				setGameLabel(resp.data.gameId, resp.data.gameLabel);
			}
		}).then(this.fetchLocks);
	},
	methods: {
		shuffle: function() {
			this.letters = _.shuffle(this.letters);
		},
		lock: function() {
			this.$confirm.require({
				message: `Are you sure you want to lock ${this.selectedWord.toUpperCase()} for ${this.score} points?`,
				group: 'game',
				header: 'Lock Word?',
				icon: 'pi pi-lock',
				acceptLabel: 'Lock',
				acceptClass: 'p-button-danger',
				accept: this.submitLock,
				rejectLabel: 'Go Back',
				rejectClass: 'p-button-text p-button-secondary',
			});
		},
		fetchLocks: function() {
			axios.get(`${BACKEND_URL}/locks/${this.gameId}`, {
				params: {
					playerId: this.userId,
				}
			}).then(resp => {
				this.leaders = _.map(resp.data, leader => [leader.playerLabel, leader.letters, leader.score]);
				this.amLocked = _.some(resp.data, 'playerId');
			});
		},
		submitLock: function() {
			const word = this.selectedWord;
			this.locking = true;
			axios.post(`${BACKEND_URL}/locks/${this.gameId}`, {
				playerId: this.userId,
				playerLabel: this.userLabel,
				letters: word,
			}).then(resp => {
				if (_.get(resp.data, 'result') === 'success') {
					this.amLocked = true;
					confetti({
						particleCount: 100,
						spread: 70,
						origin: { y: 0.6 }
					});
					this.fetchLocks();
				} else if (_.get(resp.data, 'result') === 'non_word') {
					this.$toast.add({
						severity: 'error',
						summary: `${word.toUpperCase()} is not a valid word`,
						life: 2000,
					})
				} else if (_.get(resp.data, 'result') === 'invalid_letters') {
					this.$toast.add({
						severity: 'error',
						summary: `${word.toUpperCase()} is not valid for today's game. Try refreshing your page`,
						life: 2000,
					})
				} else {
					this.$toast.add({
						severity: 'error',
						summary: `Unknown error occurred`,
						life: 2000,
					})
				}
			}).finally(() => { this.locking = false; });
		},
		share: function() {
			const url = window.location.href;
			if (navigator.share) {
				navigator.share({
					title: 'Come join my Dodgle game!',
					text: 'Come join my Dodgle game!',
					url: url,
				});
			} else {
				const linkBlob = new window.Blob([url], {type: 'text/plain'})
				const cbItem = new window.ClipboardItem({'text/plain': linkBlob})
				navigator.clipboard.write([cbItem]).then(() => {
					this.$toast.add({
						severity: 'success',
						summary: 'URL copied to clipboard',
						life: 1000,
					})
				});
			}
		},
		append: function(letter) {
			this.selected.push(letter);
		},
		removeLetter: function(idx) {
			this.selected.splice(idx, 1);
		},
	},
}
</script>

<style lang="scss">
.entered {
	display: flex;
	position: relative;
	min-height: 60px;
	justify-content: flex-start;
	align-items: center;
	background-color: var(--entry-bg);
	border: 1px solid var(--gray-600);
	border-radius: 8px;
	margin: 16px 8px;
	padding-left: 8px;
	padding-right: 25px;
	.tile {
		margin-right: 2px;
	}
	.clear {
		position: absolute;
		right: 5px;
		top: 5px;
		cursor: pointer;
		color: var(--gray-800);
	}
	.score {
		position: absolute;
		right: 5px;
		bottom: 5px;
		font-weight: bold;
		color: var(--entry-redtext);
	}
}
.available {
	display: flex;
	justify-content: center;
	margin-top: 16px;
	.tile {
		width: 50px;
		height: 50px;
		margin-left: 4px;
		margin-right: 4px;
		.letter {
			font-size: 1.5rem;
		}
	}
}
.buttons {
	margin-top: 16px;
	display: flex;
	justify-content: center;
	button {
		margin-right: 4px;
		margin-left: 4px;
		padding: 12px 20px;
	}
}
.leaderboard {
	margin-top: 16px;
	padding: 16px;
	h2 {
		display: block;
		text-align: center;
		padding: 8px 0;
	}
	.player {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 4px;
		> div {
			display: flex;
			align-items: center;
		}
		.name {
			margin-left: 8px;
			font-weight: bold;
		}
		.score {
			margin-left: 8px;
		}
		.word {
		}
	}
}
</style>
