<template>
	<div class="tile" :style="{'background-color': bgColor}">
		<span class="letter">{{ letter }}</span>
		<span class="value">{{ letterValue }}</span>
	</div>
</template>
<script>
export default {
  name: 'LetterTile',
	props: ['letter', 'letterValue'],
	computed: {
		bgColor() {
			return `var(--tile-${this.letterValue})`
		}
	},
}
</script>
<style lang="scss" scoped>
.tile {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	padding: 5px;
	border: 1px solid black;
	border-radius: 5px;
	text-transform: uppercase;
	cursor: pointer;
	
	.letter {
		font-size: 1rem;
		font-weight: bold;
		color: var(--tile-text);
	}
	.value {
		display: block;
		color: var(--tile-text);
		font-size: 12px;
		position: absolute;
		right: 0;
		bottom: 0;
		margin-right: 2px;
		margin-bottom: 2px;
	}
}
</style>
