<template>
	<div class="menu">
		<div><Button icon="pi pi-question-circle" class="p-button-text p-button-lg" @click="toggleHelp" /></div>
		<div><h1><router-link to="/">Dodgle</router-link></h1></div>
		<div><Button icon="pi pi-cog" class="p-button-text p-button-lg" @click="toggleSettings" /></div>
	</div>
	<Dialog header="Help" footer=" " v-model:visible=showHelp class="dodgle-modal help-modal" modal dismissableMask>
		<div class="help-contents">
			<div><span>&#x1F3C6;</span>Find a word with the most points and compete with your friends.</div>
			<div><span>&#x2795;</span>One extra point per unique letter used, 2 more bonus points if you use all 7 letters.</div>
			<div><span>&#x267B;</span>Letters can be <b>reused</b> as much as you want!</div>
		<div><span>&#x1F512;</span>Once you lock a word, there is no going back. You only get one submission each day.</div>
			<div><span>&#x1F440;</span>The order of the leaderboard will be visible before you lock. Other submissions and points are only visible after you lock.</div>
			<div><span>&#x1F570;</span>New letters and point values every day at Midnight Pacific Time.</div>
		</div>
	</Dialog>
	<Dialog header="Settings" v-model:visible=showSettings class="dodgle-modal settings-modal" :breakpoints="modalBreakpoints" :style="modalStyle" :draggable="false" modal dismissableMask>
		<FloatLabel>
			<InputText id="name" type="text" v-model="name" @keyup.enter="saveSettings" />
			<label for="name">What's Your Name? Emojis encouraged 👍</label>
		</FloatLabel>
		<template #footer>
			<Button v-if="$route.params.gameId" severity="warn" label="Leave Game" icon="pi pi-times" @click="leaveGameConfirm" />
			<Button label="Save" icon="pi pi-check" @click="saveSettings" />
		</template>
	</Dialog>
	<ConfirmDialog group="header" :breakpoints="modalBreakpoints" :style="modalStyle"></ConfirmDialog>

</template>

<script>
import Button from 'primevue/button';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import FloatLabel from 'primevue/floatlabel';
import { getUserId, getUserName, setUserName, getGameLabel, deleteGame } from '../utils/storage';
export default {
  name: 'HeaderBar',
  components: {
		Button,
		ConfirmDialog,
		Dialog,
		InputText,
		FloatLabel,
  },
	data() {
		return {
			name: null,
			userId: null,
			showSettings: false,
			showHelp: false,
			modalStyle: {
				width: '400px',
			},
			modalBreakpoints: {
				'550px': '90vw',
			},
		};
	},
	mounted() {
		this.userId = getUserId()
		this.name = getUserName()
		if (!this.name) {
			this.showSettings = true;
		}
	},
	methods: {
		getGameLabel,
		toggleHelp: function() { this.showHelp = !this.showHelp },
		toggleSettings: function() { this.showSettings = !this.showSettings },
		saveSettings: function() {
			if (this.name) {
				setUserName(this.name);
				this.showSettings = false;
			}
		},
		leaveGameConfirm: function() {
			const gameId = this.$route.params.gameId;
			this.$confirm.require({
				message: `Are you sure you want to leave ${getGameLabel(gameId)}? This will remove it from your game list and someone will need to send you the link again in order to re-join.`,
				header: 'Leave Game?',
				group: 'header',
				icon: 'pi pi-times',
				acceptLabel: 'Leave',
				acceptClass: 'p-button-danger',
				accept: this.leaveGame,
				rejectLabel: 'Stay',
				rejectClass: 'p-button-text p-button-secondary',
			});
		},
		leaveGame: function() {
			const gameId = this.$route.params.gameId;
			deleteGame(gameId);
			this.$router.push('/');
			this.showSettings = false;
		},
	},
}
</script>

<style lang="scss">
.menu {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 8px;
	h1 {
		margin: 15px auto;
		text-align: center;
		color: var(--text-color);
		a {
			text-decoration: none;
			color: var(--text-color);
		}
	}
}
.dodgle-modal {
	input {
		width: 100%;
	}
	.p-dialog-content {
		padding-top: 25px;
		line-height: 1.5rem;
	}
}
.help-contents {
	max-width: 350px;
	div {
		margin-bottom: 16px;
		line-height: 1.5rem;
		margin-left: 1.5rem;
		span {
			display: inline-block;
			width: 1.5rem;
			margin-left: -1.5rem;
		}
	}
}
</style>
